@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .ProjectsSliderListButtons {
      @apply opacity-100 w-[140px] h-[48px] rounded-sm flex absolute text-white text-[20px] leading-[48px] font-bold
      before:!opacity-100 before:!m-auto !p-[auto]
      ;
    }
    .ContactInput {
      @apply w-full border-b-[1px] border-[#D7D8D9] text-[25px] transition duration-150 ease-in-out focus:outline-none focus:border-[#ff5e1485];
    }

    .navbarMobile{
      @apply w-3/4  m-auto mt-5 border-b-[1px]
    }
    .navbarDesktop{
      @apply w-full flex justify-center items-center font-semibold
    }
  }

  @layer utilities{
    .scrollbar::-webkit-scrollbar {
      width: 0px;
    }
  
    .scrollbar::-webkit-scrollbar-track {
      border-radius: 100vh;
      background: white;
    }
  
    .scrollbar::-webkit-scrollbar-thumb {
      background: black;
      border-radius: 100vh;
      border: 2px solid #f6f7ed;
    }
  
    .scrollbar::-webkit-scrollbar-thumb:hover {
      background: black;
    }
  }