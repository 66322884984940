
.slick-dots{
    transform: scale(1.20);
    color: #001659 !important;
  }
  .slick-dots button:before {
    transform: scale(1.20);
    color: #001659 !important;
    opacity: 0.5 !important;
  }
  .slick-dots li.slick-active button:before {
    transform: scale(1.5);
    color: #001659 !important;
    opacity: 1 !important;
  }

  .slick-track{
  display: flex;
  align-items: center;
  }